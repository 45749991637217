@tailwind base;
@tailwind components;
@tailwind utilities;




.wrapper{
    width: 190px;
    justify-content: center;
    display: flex;
}
.name{
    display: flex;
    align-items: center;
    text-align: center;
}
.verify{
    width: 15px;
    height: 15px;
    margin: 0px 5px;
    background-image: url(./images/verify.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
.profile{
    width: 100%;
    height: 200px;
    background-color: red;
}
.bac{
    background-color: blue;
}
.pic{
    width: 80px;
    height: 80px;
    background-image: url(./images/chris.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0px auto;
    border-radius: 50%;
}
.profile--container{
    text-align: center;
    margin-top: 10px;
}
.profile-picture{
    width: 20px;
    height: 20px;
    margin: 0px 3px;
    background-image: url(./images/verify.png);
    background-position: center;
    background-repeat: no-repeat; 
    background-size: contain;   
}
.name-profile{
    font-size: 18px;
    font-weight: 500;

}
.name-profile-section{
    display: flex;
    justify-content: center;
    align-items: center;
}
.grey{
    color: rgb(123, 123, 123);
}
.nav-container{
    background-color: white;
 position: sticky;
 top: 0;
 border-bottom: 0.5px solid rgb(169, 169, 169);
}
.profile-button{
    border:0.7px solid rgb(169, 169, 169) ;
    padding: 0px 10px;
    margin-top: 7px;
    border-radius: 3px;
}
.style--button{
    width: 20%;
    height: 45px;
    background-color: rgb(230, 230, 230);
    color: #395dff;
    border-radius: 0px 40px 40px 0px;
}
.style--input{
width: 80%;
height: 45px;
font-size: 20px;
padding-left: 30px;
background-color: rgb(230, 230, 230);
color: black;
border-radius: 40px 0px 0px 40px;
outline: none;

}
.style--input::placeholder{
    color: rgb(108, 107, 107);
    font-size: 18px;
    font-weight: 300;
}
